{#if confirm || prompt}
  <div class="ui-pnotify-confirm">
    {#if prompt}
      <div
          class="
            ui-pnotify-prompt-bar
            {_notice.get()._styles.promptBar ? _notice.get()._styles.promptBar : ''}
            {_notice.get()._styles.text ? _notice.get()._styles.text : ''}
          ">
        {#if promptMultiLine}
          <textarea
              rows="5"
              on:keypress="handleKeyPress(event)"
              ref:promptMulti
              class="
                ui-pnotify-prompt-input
                {_notice.get()._styles.input ? _notice.get()._styles.input : ''}
                {promptClass}
              "
              bind:value="promptValue"></textarea>
        {:else}
          <input
              type="text"
              on:keypress="handleKeyPress(event)"
              ref:promptSingle
              class="
                ui-pnotify-prompt-input
                {_notice.get()._styles.input ? _notice.get()._styles.input : ''}
                {promptClass}
              "
              bind:value="promptValue" />
        {/if}
      </div>
    {/if}
    <div
        class="
          ui-pnotify-action-bar
          {_notice.get()._styles.actionBar ? _notice.get()._styles.actionBar : ''}
          {_notice.get()._styles.text ? _notice.get()._styles.text : ''}
        "
        style="justify-content: {align};"
        ref:buttons>
      {#each buttons as button}
        <button
            type="button"
            on:click="handleClick(button, event)"
            class="
              ui-pnotify-action-button
              {button.primary ? (_notice.get()._styles.btnPrimary ? _notice.get()._styles.btnPrimary : '') : (_notice.get()._styles.btn ? _notice.get()._styles.btn : '')}
              {button.addClass ? button.addClass : ''}
            ">{#if button.textTrusted}{@html button.text}{:else}{button.text}{/if}</button>
      {/each}
    </div>
  </div>
{/if}

<script>
  import PNotify from './PNotify.html';

  export default {
    setup (Component) {
      Component.key = 'Confirm';

      Component.defaults = {
        // Make a confirmation box.
        confirm: false,
        // Make a prompt.
        prompt: false,
        // Classes to add to the input element of the prompt.
        promptClass: '',
        // The value of the prompt.
        promptValue: '',
        // Whether the prompt should accept multiple lines of text.
        promptMultiLine: false,
        // For confirmation boxes, true means the first button or the button with promptTrigger will be focused, and null means focus will change only for modal notices. For prompts, true or null means focus the prompt. When false, focus will not change.
        focus: null,
        // Where to align the buttons. (flex-start, center, flex-end, space-around, space-between)
        align: 'flex-end',
        // The buttons to display, and their callbacks.
        buttons: [
          {
            text: 'Ok',
            textTrusted: false,
            addClass: '',
            primary: true,
            // Whether to trigger this button when the user hits enter in a single line prompt. Also, focus the button if it is a modal prompt.
            promptTrigger: true,
            click: (notice, value) => {
              notice.close();
              notice.fire('pnotify.confirm', { notice, value });
            }
          },
          {
            text: 'Cancel',
            textTrusted: false,
            addClass: '',
            click: (notice) => {
              notice.close();
              notice.fire('pnotify.cancel', { notice });
            }
          }
        ]
      };

      // Register the module with PNotify.
      PNotify.modules.Confirm = Component;
      // Append this module to the container.
      PNotify.modulesAppendContainer.push(Component);

      // Add button styles to styling objects.
      Object.assign(PNotify.styling.brighttheme, {
        actionBar: '',
        promptBar: '',
        btn: '',
        btnPrimary: 'brighttheme-primary',
        input: ''
      });
      Object.assign(PNotify.styling.bootstrap3, {
        actionBar: 'ui-pnotify-confirm-ml',
        promptBar: 'ui-pnotify-confirm-ml',
        btn: 'btn btn-default ui-pnotify-confirm-mx-1',
        btnPrimary: 'btn btn-default ui-pnotify-confirm-mx-1 btn-primary',
        input: 'form-control'
      });
      Object.assign(PNotify.styling.bootstrap4, {
        actionBar: 'ui-pnotify-confirm-ml',
        promptBar: 'ui-pnotify-confirm-ml',
        btn: 'btn btn-secondary mx-1',
        btnPrimary: 'btn btn-primary mx-1',
        input: 'form-control'
      });
      if (!PNotify.styling.material) {
        PNotify.styling.material = {};
      }
      Object.assign(PNotify.styling.material, {
        actionBar: '',
        promptBar: '',
        btn: '',
        btnPrimary: 'ui-pnotify-material-primary',
        input: ''
      });
    },

    oncreate () {
      this.fire('init', { module: this });
    },

    data () {
      return Object.assign({
        '_notice': null, // The PNotify notice.
        '_options': {} // The options for the notice.
      }, PNotify.modules.Confirm.defaults);
    },

    methods: {
      initModule (options) {
        this.set(options);
      },

      afterOpen () {
        if (this.get().prompt && this.get().focus !== false) {
          if (this.get().promptMultiLine) {
            this.refs.promptMulti.focus();
          } else {
            this.refs.promptSingle.focus();
          }
        } else if (this.get().confirm &&
          (
            this.get().focus === true ||
            (this.get().focus === null && this.get()._options.stack.modal)
          )
        ) {
          const buttons = this.get().buttons;
          if (buttons.length) {
            let i = buttons.length - 1;
            while (i >= 0) {
              if (buttons[i].promptTrigger) {
                break;
              }
              i--;
            }
            this.refs.buttons.children[i].focus();
          }
        }
      },

      handleClick (button, event) {
        if (button.click) {
          button.click(this.get()._notice, this.get().prompt ? this.get().promptValue : null, event);
        }
      },

      handleKeyPress (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault();
          const { buttons } = this.get();
          for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].promptTrigger && buttons[i].click) {
              buttons[i].click(this.get()._notice, this.get().prompt ? this.get().promptValue : null, event);
            }
          }
        }
      }
    }
  };
</script>

<style>
  .ui-pnotify-action-bar,
  .ui-pnotify-prompt-bar {
    margin-top: 5px;
    clear: both;
  }
  .ui-pnotify-action-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .ui-pnotify-prompt-input {
    margin-bottom: 5px;
    display: block;
    width: 100%;
  }
  .ui-pnotify-confirm-mx-1 {
    margin: 0 5px;
  }
  :global(.ui-pnotify.ui-pnotify-with-icon) .ui-pnotify-confirm-ml {
    margin-left: 24px;
  }
  :global([dir=rtl] .ui-pnotify.ui-pnotify-with-icon) .ui-pnotify-confirm-ml {
    margin-right: 24px;
    margin-left: 0;
  }
</style>
